import PropTypes from 'prop-types';
import React, { Fragment, useContext } from 'react';
import BlogCard from '@components/molecules/Cards/BlogCard/BlogCard';
import { SiteContext } from '@store/Provider';
import { useUpdateDimensions } from '@hooks/useUpdateDimensions';
import { isPostLocked } from '@helpers/isPostLocked';

const BlogCardComponent = ({ post, isVertical, type, isLocked }) => {
  return (
    <BlogCard
      {...(post.postMeta.blogPostType !== 'default' && {
        icon: post.postMeta.blogPostType,
      })}
      href={post.uri}
      category={post.categories.nodes[0].name}
      date={post.date}
      title={post.title}
      description={post.excerpt}
      type={type}
      imageUrl={post.featuredImage?.node.publicUrl}
      selectCardStyles="biggerCardStyles"
      isVertical={isVertical}
      isLocked={isLocked}
    />
  );
};

const BlogPostSection = ({ blogPosts }) => {
  const blogPostsCopy = [...blogPosts];
  const firstGroup = blogPostsCopy.slice(0, 3);
  const secondGroup = blogPostsCopy.slice(3, 5);
  const lastGroup = blogPostsCopy.slice(5);
  const { isLoggedIn } = useContext(SiteContext);
  const { height } = useUpdateDimensions();
  const isLandscapeMobile = height < 450;
  return (
    <Fragment>
      <div className="mr-10">
        {firstGroup.map((post, index) => {
          if (index === 0) {
            return (
              <div
                key={post.id}
                className={`${
                  isLandscapeMobile ? 'mb-4' : 'mb-10 '
                } rounded-20 inline-block`}
              >
                <BlogCardComponent
                  post={post}
                  type="large"
                  isLocked={isPostLocked(
                    isLoggedIn,
                    post.postMeta.blogPostStatus,
                  )}
                />
              </div>
            );
          }
          return (
            <div
              key={post.id}
              className={`inline-block rounded-20 ${
                index === 1 ? 'mr-10' : ''
              }`}
            >
              <BlogCardComponent
                post={post}
                type="regularwithbutton"
                isLocked={isPostLocked(
                  isLoggedIn,
                  post.postMeta.blogPostStatus,
                )}
              />
            </div>
          );
        })}
      </div>
      {!!secondGroup.length && (
        <div className="mr-10 inline-block rounded-20">
          {secondGroup.map((post, index) => (
            <div
              className={`${
                index === 0 ? (isLandscapeMobile ? 'mb-4' : 'mb-10') : ''
              }`}
              key={post.id}
            >
              <BlogCardComponent
                post={post}
                type="regular"
                isLocked={isPostLocked(
                  isLoggedIn,
                  post.postMeta.blogPostStatus,
                )}
              />
            </div>
          ))}
        </div>
      )}
      {!!lastGroup.length && (
        <div key={lastGroup[0].id} className="mr-10 inline-block rounded-20">
          <BlogCardComponent
            post={lastGroup[0]}
            isVertical
            type="large"
            isLocked={isPostLocked(
              isLoggedIn,
              lastGroup[0].postMeta.blogPostStatus,
            )}
          />
        </div>
      )}
    </Fragment>
  );
};

BlogPostSection.propTypes = {
  blogPosts: PropTypes.arrayOf(
    PropTypes.shape({
      isLocked: PropTypes.bool,
      isVertical: PropTypes.bool,
      post: PropTypes.shape({
        categories: PropTypes.shape({
          nodes: PropTypes.any,
        }),
        date: PropTypes.string,
        excerpt: PropTypes.string,
        featuredImage: PropTypes.shape({
          node: PropTypes.shape({
            publicUrl: PropTypes.string,
          }),
        }),
        postMeta: PropTypes.shape({
          blogPostType: PropTypes.string,
        }),
        title: PropTypes.string,
        uri: PropTypes.string,
      }),
      type: PropTypes.string,
    }),
  ),
};

export default BlogPostSection;

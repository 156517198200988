import React from 'react';
import SEO from '@components/molecules/SEO';
import useToggle from '@hooks/useToggle';
import TopFixedSection from '@components/organisms/TopFixedSection';
import { Helmet } from 'react-helmet';
import Image from '@components/molecules/ImageGatsby';
import { graphql } from 'gatsby';
import BlogPostSection from '@components/molecules/BlogPostsSection';
import { createBlogPostsPatternSet } from '@helpers/createBlogPostsPatternSet';
import { Swiper } from 'swiper/react';
import { SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import { useDisablePageScroll } from '@hooks/useDisablePageScroll';
import { breakpoints } from '@helpers/constants';
import useBreakpoint from '@hooks/useBreakpoint';
import BackgroundImage from '@images/blog_page_background.jpg';
import BackgroundImageMobile from '@images/mobile_blog_page_background.jpg';

const Blog = ({
  data: {
    allWp: {
      nodes
    },
    wp: {
      siteSettings: {
        siteSettings: { menuLogo, menuText },
      },
    },
    allWpPost,
  },
}) => {
  const translations = [];
  const { toggled, handleToggleClick } = useToggle(false);
  const isMobile = useBreakpoint(breakpoints.sm);
  const result = createBlogPostsPatternSet(allWpPost.nodes);

  useDisablePageScroll();

  return (
    <div className="bg-carbon">
      <Helmet
        htmlAttributes={{
          lang: 'bg',
        }}
      />
      <SEO title="Blog page" uri="/blog" translations={translations} />
      <div className="section flex">
        <Image
          image={isMobile ? BackgroundImageMobile : BackgroundImage}
          fill
        />
        <TopFixedSection
          withoutBlurEffect
          menuText={menuText}
          menuLogo={menuLogo}
          handleToggleClick={handleToggleClick}
          toggled={toggled}
          generalSettingsUrl={nodes[0].allSettings.generalSettingsUrl}
        />
        <div className="flex overflow-x-auto blog-swiper cursor-pointer">
          <Swiper
            grabCursor
            slidesPerView="auto"
            mousewheel={true}
            freeMode={true}
            modules={[FreeMode, Mousewheel]}
          >
            <SwiperSlide>
              {result.map((blogPosts, index) => (
                <BlogPostSection blogPosts={blogPosts} key={index} />
              ))}
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Blog;

export const query = graphql`
  query BLOG_PAGE {
    allWp {
      nodes {
        allSettings {
          generalSettingsUrl
        }
      }
    }
    wp {
      siteSettings {
        siteSettings {
          menuText
          menuLogo {
            altText
            mediaItemUrl
          }
        }
      }
    }
    allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        postMeta {
          blogPostStatus
          blogPostType
        }
        uri
        categories {
          nodes {
            name
            slug
          }
        }
        date(formatString: "D/M/yyyy")
        title
        excerpt
        featuredImage {
          node {
            publicUrl
          }
        }
        id
      }
    }
  }
`;
